<script lang="ts">
    import 'o365.dataObject.extension.FileUpload.ts';
</script>
<script setup lang="ts">
    // Libraries
    import {
        onMounted,
        onUnmounted,
        onUpdated,
        defineExpose,
        defineProps,
        computed,
        ref
    } from 'vue';

    // ---- Components ---- //
    import OModal from 'o365.vue.components.Modal.vue';

    // ---- Modules ---- //
    import DataObject from 'o365.modules.DataObject.ts';

    // ---- Controls ---- //
    import FileUpload from 'o365.controls.FileUpload.ts';

    // ---- Props ---- //
    const props = defineProps({
        /** Dataobject that will be used when uploading files */
        dataObject: {
            type: DataObject,
            required: true
        },
        /** Specifies if the file picker should support picking multiple files */
        multipleFilePicker: {
            type: Boolean,
            required: false,
            default: true
        },
        /** Specifies how multiple files should be uploaded. Allowed values are [bulk] and [batch] */
        multiUploadType: {
            type: String,
            required: false,
            default: 'batch',
            validator(value) {
                return ['bulk', 'batch'].includes(value)
            }
        },
        /** Replace the text on the default upload button.
         *  Only used when the default upload button is being used.
         */
        uploadButtonText: {
            type: String,
            required: false,
            default: 'Upload File'
        },
        /** Specifies if the FileUpload should support drag and drop files */
        supportDrop: {
            type: Boolean,
            required: false,
            default: false
        },
        /** Specifies if the FileUpload should support pasting files */
        supportPaste: {
            type: Boolean,
            required: false,
            default: false
        },
        /** Specifies if the FileUpload should disable the default upload button
         *  Only be used if no template is added to the default slot
         */
        disableDefaultButton: {
            type: Boolean,
            required: false,
            default: false
        }
    });
    // ---- Variables ---- //
    const fileUploadProgressModal = ref(null);
    const fileUploadWrapper = ref(null);
    const showDropZoneOverlay = ref(false);
    const uploadProgress = ref(null);

    const fileUploadProgressModalOptions = {
        backdrop: 'static',
        keyboard: false
    }

    const fileSelector = document.createElement('input');
    
    fileSelector.setAttribute('type', 'file');

    if (props.multipleFilePicker) {
        fileSelector.setAttribute('multiple', null);
    }

    // ---- Computed Properties ---- //
    const numberOfFilesToUpload = computed(() => {
        return uploadProgress?.filesToUpload ?? 0;
    });

    const numberOfFilesUploaded = computed(() => {
        return uploadProgress?.filesUploaded ?? 0;
    });

    const currentFileUploadProgress = computed(() => {
        return uploadProgress?.currentFileUploadProgress ?? 0;
    });

    const currentFileUploadName = computed(() => {
        return uploadProgress?.currentFileUploadName ?? 'Waiting for upload to start';
    });

    const fileUploadError = computed(() => {
        return uploadProgress?.error;
    });

    


    // ---- Methods ---- //
    function onFileInputChanged(event: Event): void {
        const files = event.target.files;
        
        // Reset hidden file input
        event.target.setAttribute('type', 'text');
        event.target.setAttribute('type', 'file');
debugger;
        uploadFiles(files);
    }

    function onClick(event: PointerEvent): void {
        fileSelector.click();
    }

    function onDrop(event: DragEvent): void {
        setDropZoneInactive();

        uploadFiles(event.dataTransfer.files);
    }

    function onPaste(event: ClipboardEvent): void {
        debugger;
    }

    function onDragEnter(event: DragEvent): void {
        setDropZoneActive();
    }

    function onDragLeave(event: DragEvent): void {
        // Returns if drop zone is already hidden
        if (showDropZoneOverlay.value === false) {
            return;
        }

        // If cursor hits FileUpload wrapper
        // Disable drop zone
        if (event.target === fileUploadWrapper.value) {
            setDropZoneInactive();
            return;
        }

        // Loop over all parent elements
        // Return if wrapper is in parent path as cursor has not left drop zone
        let target = event.target;

        while (target) {
            if (target === fileUploadWrapper.value) {
                return;
            }

            target = target.parentElement;
        }

        // Default behavior is to disable drop zone since wrapper was not found in parent path
        setDropZoneInactive();
    }

    // Activate dropzone
    function setDropZoneActive(): void {
        showDropZoneOverlay.value = true;
    }

    // Deactivate dropzone
    function setDropZoneInactive(): void {
        showDropZoneOverlay.value = false;
    }

    function uploadFiles(files: File[]): void {
        if (files.length === 0) {
            return;
        }

        var options = {
            dataObject: props.dataObject,
            onUploadProgress: onUploadProgress
        };
        
        if (files.length === 1) {
            options.file = files[0];

            FileUpload.uploadFile(options);
        } else if (props.multiUploadType === 'batch') {
            options.files = files;

            FileUpload.batchUploadFiles(options);
        } else if (props.multiUploadType === 'bulk') {
            options.files = files;

            FileUpload.bulkUploadFiles(options);
        } else {
            throw new Error('Invalid upload state');
            return;
        }

        fileUploadProgressModal.value.show();
    }

    function onUploadProgress(progress: API.IUploadProgress) {
        uploadProgress.value = progress;
    }

    // ---- Lifecycle Hooks ---- //
    onMounted((): void => {
        fileSelector.addEventListener('change', onFileInputChanged);
    });

    onUnmounted((): void => {
        fileSelector.removeEventListener('change', onFileInputChanged);
    });

    onUpdated((): void => {
        console.log('Updating');
    });
</script>

<template>
    <div
        ref="fileUploadWrapper"
        :class="{ dropzone: showDropZoneOverlay }"
        @[props.supportDrop&&`drop`].stop.prevent="onDrop"
        @[props.supportPaste&&`paste`].stop.prevent="onPaste" 
        @[props.supportDrop&&`dragenter`].stop.prevent="onDragEnter"
        @[props.supportDrop&&`dragover`].stop.prevent="onDragEnter"
        @[props.supportDrop&&`dragleave`].stop.prevent="onDragLeave"
        style="position: relative;"
    >
        <OModal ref="fileUploadProgressModal" :modalOptions="fileUploadProgressModalOptions">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">File Upload</h5>
                    </div>
                    <div class="modal-body d-flex flex-column">
                        <div class="d-flex flex-row" v-if="numberOfFilesToUpload > 0">
                            <p>Upload progress: {{numberOfFilesUploaded}} of {{numberOfFilesToUpload}}</p>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="[{ width: `${numberOfFilesUploaded / numberOfFilesToUpload}%`}]"></div>
                            </div>
                        </div>

                        <div class="d-flex flex-row">
                            <p>Currently uploading: {{currentFileUploadName}}</p>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="[{ width: `${currentFileUploadProgress}%`}]"></div>
                            </div>
                        </div>

                        <div class="d-flex flex-row alert alert-warning" v-if="fileUploadError">
                            <p>{{fileUploadError}}</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary">Save changes</button>
                        <button class="btn btn-warning" data-bs-dismiss="modal">{{ $t('Cancel') }}</button>
                    </div>
                </div>
            </div>
        </OModal>

        <slot :onClick="onClick">
            <button class="btn btn-outline-primary" @click="onClick" v-if="!disableDefaultButton">{{ props.uploadButtonText }}</button>
    
        </slot>
    </div>
</template>

<style scoped>
    .dropzone:after {
        content: "Drop files here...";
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        inset: 0;
        background-color: rgba(255, 255, 255, .8);
        border: 'dashed grey 4px';
        z-index: 1000;
    }
</style>
